<template>
  <div id="page">
    <PageHeader
      :pageTitle="title + ' - ' + pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="8">
        <!-- global component -->
        <Notes :route="`/companies/${this.$route.params.id}/notes`" />
        <!-- <Notelist></Notelist> -->
      </v-col>

      <v-col cols="4">
        <Subnavigation
          root="/csm/company-manager/:id"
          :pageTitle="pageTitle"
        ></Subnavigation>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import Subnavigation from "@/components/ui/Subnavigation.vue";
//import Notelist from "@/components/companies/notes/Notelist.vue";
import Notes from "@/components/global/notes/Notes.vue";
export default {
  name: "CompanyNotes",

  components: {
    PageHeader,
    Subnavigation,
    //Notelist,
    Notes,
  },

  data() {
    return {
      pageTitle: "Notizen",
      pageDescription: "Notizen zu diesem Kunden.",
      title: "",
      preloader: false,
    };
  },
  created: function () {
    this.getData(); // call it immediatly
  },
  methods: {
    async getData() {
      /* Fetching Companyname for Headline */
      /* USEING REUSEABLE MIXIN METHOD */
      const response = await this.getRequest(
        "companies/" + this.$route.params.id
      ); // await data from mixin
      this.title = response.data.data[0].name;
    },
  },
  mixins: [apiRequest],
};
</script>
